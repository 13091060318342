import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
} from "@chakra-ui/react";

import { FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";

import Image from "next/image";
// import Imagelog from "../public/assets/logo1.jpg";
import Imagelog from "../public/logo.png";

const SocialButton = ({ children, label, href, color }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      <Box color={color}>{children}</Box>
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Image src={Imagelog} width={160} height={100} />
            </Box>
            <Text fontSize={"sm"}>
              © 2023-2025 Unlimiteye solutions pvt. ltd. All rights reserved
            </Text>
            <Stack direction={"row"} spacing={6}>
              <SocialButton
                label={"LinkedIn"}
                href={"https://www.linkedin.com/company/aptofy"}
                color={"#0A66C2"} // LinkedIn blue
              >
                <FaLinkedin />
              </SocialButton>
              <SocialButton
                label={"YouTube"}
                href={"https://www.youtube.com/@Aptofy_You"}
                color={"#FF0000"} // YouTube red
              >
                <FaYoutube />
              </SocialButton>
              <SocialButton
                label={"LinkedIn"}
                href={"https://www.linkedin.com/company/unlimiteye"}
                color={"#0A66C2"} // LinkedIn blue
              >
                <FaLinkedin />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Product</ListHeader>
            <Link href={"#"}>Overview</Link>
            <Link href={"#"}>Features</Link>
            <Link href={"#"}>Tutorials</Link>
            <Link href={"#"}>Pricing</Link>
            <Link href={"#"}>Releases</Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Company</ListHeader>
            <Link href={"/AboutUs"}>About us</Link>
            <Link href={"https://guptamanjari.wordpress.com/"}>Blog</Link>
            <Link href={"/contactPage"}>Contact us</Link>
            <Link href={"#"}>Pricing</Link>
            <Link href={"#"}>Testimonials</Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Support</ListHeader>
            <Link href={"/Faq"}>Help Center</Link>
            <Link href={"/TermsofService"}>Terms of Service</Link>
            <Link href={"/RefundPolicy"}>Refund Policy</Link>
            <Link href={"/PrivacyPolicy"}>Privacy Policy</Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
